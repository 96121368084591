import axios from "axios";

const API_URL = process.env.REACT_APP_API_Link;

const headers2 = {
  "Content-Type": "application/json",
};

const registerForm = (certificate) => {
  return axios.get(API_URL + `validate-voucher-pyt/${certificate}`, {
    headers: headers2,
  });
};

const getStates = () => {
  return axios.get(API_URL + `states`, {
    headers: headers2,
  });
};
const getTrips = (certificate) => {
  return axios.get(API_URL + `trip-by-voucher/${certificate}`, {
    headers: headers2,
  });
};

const submitVoucher = (values) => {
  return axios.post(API_URL + "submit-voucher-pyt", values, {
    headers: headers2,
  });
};

const UserListService = { registerForm, getStates, getTrips, submitVoucher };

export default UserListService;
