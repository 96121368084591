import React, { useEffect, useState } from "react";
import Sidebar from "../includes/Sidebar";
import MainHeading from "../includes/MainHeading";
import Footer from "../includes/Footer";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AdminListService from "../../../services/admin-list.service";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import ReactLoading from "react-loading";
import { updateVoucher } from "../../../schema";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import ImageLoader from "../includes/ImageLoader";
import $ from "jquery";

export default function EditVoucher() {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [formSubmiting, setFormSubmiting] = useState(false);

  const [errorsServer, setErrorsServer] = useState([]);

  const state = useSelector((state) => state.stateVals);
  const { adminAccessToken, admin_id } = state;
  const navigate = useNavigate();

  useEffect(() => {
    if (adminAccessToken) {
      const getSingleVoucher = async () => {
        setLoading(true);
        try {
          const response = await AdminListService.getVoucherById(id);

          setValues({
            Company: response?.data?.response?.company,
            Certificate: response?.data?.response?.certificate,
            Amount: response?.data?.response?.amount,

            Received:
              response?.data?.response?.received !== "" &&
              response?.data?.response?.received !== " " &&
              response?.data?.response?.received !== "0000-00-00" &&
              moment(response?.data?.response?.received).format("YYYY-MM-DD"),
            First: response?.data?.response?.fname,
            Street: response?.data?.response?.street,
            State: response?.data?.response?.state,

            issuance:
              response?.data?.response?.issuance !== "" &&
              response?.data?.response?.issuance !== " " &&
              response?.data?.response?.issuance !== "0000-00-00" &&
              moment(response?.data?.response?.issuance).format("YYYY-MM-DD"),

            CertiStatus: response?.data?.response?.status,
            Job: response?.data?.response?.job,
            Concatenated: response?.data?.response?.concatenated,
            shipCost: response?.data?.response?.shipCost,
            Shipped:
              response?.data?.response?.shipped !== "" &&
              response?.data?.response?.shipped !== " " &&
              response?.data?.response?.shipped !== "0000-00-00" &&
              moment(response?.data?.response?.shipped).format("YYYY-MM-DD"),
            Fulfilled: response?.data?.response?.fulfilled,
            Last: response?.data?.response?.lname,
            City: response?.data?.response?.city,
            Zip: response?.data?.response?.zip,
            Expiration:
              response?.data?.response?.expiration !== "" &&
              response?.data?.response?.expiration !== " " &&
              response?.data?.response?.expiration !== "0000-00-00" &&
              moment(response?.data?.response?.expiration).format("YYYY-MM-DD"),
            choice1: response?.data?.response?.choice01,
            choice2: response?.data?.response?.choice02,
            choice3: response?.data?.response?.choice03,
            choice4: response?.data?.response?.choice04,
            choice5: response?.data?.response?.choice05,
            choice6: response?.data?.response?.choice06,
            choice7: response?.data?.response?.choice07,
            choice8: response?.data?.response?.choice08,
            choice9: response?.data?.response?.choice09,
            choice10: response?.data?.response?.choice10,
            choice11: response?.data?.response?.choice11,
            choice12: response?.data?.response?.choice12,
            choice13: response?.data?.response?.choice13,
            choice14: response?.data?.response?.choice14,
            choice15: response?.data?.response?.choice15,
            choice16: response?.data?.response?.choice16,
            choice17: response?.data?.response?.choice17,
            choice18: response?.data?.response?.choice18,
            choice19: response?.data?.response?.choice19,
            choice20: response?.data?.response?.choice20,
            choice21: response?.data?.response?.choice21,
            choice22: response?.data?.response?.choice22,
            choice23: response?.data?.response?.choice23,
            choice24: response?.data?.response?.choice24,
            choice25: response?.data?.response?.choice25,
          });

          setLoading(false);
        } catch (err) {
          console.log(err);
          setLoading(false);

          if (
            err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
          ) {
            toast.error(err?.response?.data?.message, {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          } else {
            toast.error("Something went wrong, try again", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
        }
      };
      getSingleVoucher();
    } else {
      navigate("/admin/login", { replace: true });
    }
    // eslint-disable-next-line
  }, []);

  const updateVoucherFun = async (values) => {
    setFormSubmiting(true);
    try {
      const response = await AdminListService.updateVoucherById(
        values,
        admin_id,
        id
      );

      toast.success(response?.data?.response, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setFormSubmiting(false);
    } catch (err) {
      console.log(err);
      setFormSubmiting(false);

      if (
        err?.response?.data?.response &&
        typeof err?.response?.data?.response === "string"
      ) {
        toast.error(err?.response?.data?.response, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        let errorData = {};

        errorData["Company"] =
          err.response?.data &&
          err.response?.data?.company &&
          err.response?.data?.company[0];
        errorData["Job"] =
          err.response?.data &&
          err.response?.data?.job &&
          err.response?.data?.job[0];
        errorData["Certificate"] =
          err.response?.data &&
          err.response?.data?.certificate &&
          err.response?.data?.certificate[0];
        errorData["Expiration"] =
          err.response?.data &&
          err.response?.data?.expiration &&
          err.response?.data?.expiration[0];
        errorData["Concatenated"] =
          err.response?.data &&
          err.response?.data?.concatenated &&
          err.response?.data?.concatenated[0];
        errorData["Amount"] =
          err.response?.data &&
          err.response?.data?.amount &&
          err.response?.data?.amount[0];
        errorData["shipCost"] =
          err.response?.data &&
          err.response?.data?.shipCost &&
          err.response?.data?.shipCost[0];
        errorData["Shipped"] =
          err.response?.data &&
          err.response?.data?.shipped &&
          err.response?.data?.shipped[0];
        errorData["Received"] =
          err.response?.data &&
          err.response?.data?.received &&
          err.response?.data?.received[0];

        errorData["First"] =
          err.response?.data &&
          err.response?.data?.fname &&
          err.response?.data?.fname[0];
        errorData["Last"] =
          err.response?.data &&
          err.response?.data?.lname &&
          err.response?.data?.lname[0];
        errorData["Street"] =
          err.response?.data &&
          err.response?.data?.street &&
          err.response?.data?.street[0];

        errorData["City"] =
          err.response?.data &&
          err.response?.data?.city &&
          err.response?.data?.city[0];

        errorData["State"] =
          err.response?.data &&
          err.response?.data?.state &&
          err.response?.data?.state[0];

        errorData["issuance"] =
          err.response?.data &&
          err.response?.data?.issuance &&
          err.response?.data?.issuance[0];

        errorData["Fulfilled"] =
          err.response?.data &&
          err.response?.data?.fulfilled &&
          err.response?.data?.fulfilled[0];

        errorData["Zip"] =
          err.response?.data &&
          err.response?.data?.zip &&
          err.response?.data?.zip[0];

        errorData["Denomination"] =
          err.response?.data &&
          err.response?.data?.denomination &&
          err.response?.data?.denomination[0];

        setErrorsServer(errorData);
        $("html, body").animate(
          {
            scrollTop: 0,
          },
          600
        );
        toast.error("Something went wrong, try again", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  const {
    values,
    errors,
    touched,
    setValues,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      Company: "",
      Certificate: "",
      Amount: "",
      Received: "",
      First: "",
      Street: "",
      State: "",
      issuance: "",
      CertiStatus: "",
      Job: "",
      Concatenated: "",
      Shipped: "",
      shipCost: "",
      Fulfilled: "",
      Last: "",
      City: "",
      Zip: "",
      Expiration: "",
      choice1: "",
      choice2: "",
      choice3: "",
      choice4: "",
      choice5: "",
      choice6: "",
      choice7: "",
      choice8: "",
      choice9: "",
      choice10: "",
      choice11: "",
      choice12: "",
      choice13: "",
      choice14: "",
      choice15: "",
      choice16: "",
      choice17: "",
      choice18: "",
      choice19: "",
      choice20: "",
      choice21: "",
      choice22: "",
      choice23: "",
      choice24: "",
      choice25: "",
    },
    validationSchema: updateVoucher,
    onSubmit: (values) => {
      updateVoucherFun(values);
    },
  });

  const handleButtonClick = () => {
    setTimeout(() => {
      const firstError = document.querySelector(".error-message");
      if (firstError) {
        // firstError.scrollIntoView({ behavior: "smooth" });
        const rect = firstError.getBoundingClientRect();
        const offsetTop = rect.top - 200 + window.scrollY;

        window.scrollTo({
          top: offsetTop,
          behavior: "smooth",
        });
      }
    }, 500);
  };
  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <Sidebar />
          <MainHeading heading="Update Voucher" />

          <form className="mt-4 row" onSubmit={handleSubmit} noValidate>
            <div className="col-md-12 col-12">
              <div className="card dashboard-cards">
                <div className="bg-white  py-3 card-header">
                  <h4 className="mb-0">Edit Your Voucher</h4>
                </div>
                <div className="card-body">
                  {loading ? (
                    <Skeleton
                      count={20}
                      height="58px"
                      width="48.9%"
                      inline={true}
                      className="m-2"
                    />
                  ) : (
                    <div className="row">
                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>
                            Company <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Company"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="Company"
                            value={values.Company || ""}
                          />
                          {errors.Company && touched.Company ? (
                            <span className="text-danger error-message">
                              {errors.Company}
                            </span>
                          ) : errorsServer.Company ? (
                            <span className="text-danger error-message">
                              {errorsServer.Company}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>
                            Job <span className="text-danger">*</span>
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Job"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="Job"
                            value={values.Job || ""}
                          />
                          {errors.Job && touched.Job ? (
                            <span className="text-danger error-message">
                              {errors.Job}
                            </span>
                          ) : errorsServer.Job ? (
                            <span className="text-danger error-message">
                              {errorsServer.Job}
                            </span>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>
                            Certificate <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Certificate"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="Certificate"
                            value={values.Certificate || ""}
                          />
                          {errors.Certificate && touched.Certificate ? (
                            <span className="text-danger error-message">
                              {errors.Certificate}
                            </span>
                          ) : errorsServer.Certificate ? (
                            <span className="text-danger error-message">
                              {errorsServer.Certificate}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>
                            Concatenated <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Concatenated"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="Concatenated"
                            value={values.Concatenated || ""}
                          />
                          {errors.Concatenated && touched.Concatenated ? (
                            <span className="text-danger error-message">
                              {errors.Concatenated}
                            </span>
                          ) : errorsServer.Concatenated ? (
                            <span className="text-danger error-message">
                              {errorsServer.Concatenated}
                            </span>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>Amount</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Amount"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="Amount"
                            value={values.Amount || ""}
                          />
                          {errors.Amount && touched.Amount ? (
                            <span className="text-danger error-message">
                              The amount must be a number.
                            </span>
                          ) : errorsServer.Amount ? (
                            <span className="text-danger error-message">
                              {errorsServer.Amount}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>Ship Cost</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Ship Cost"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="shipCost"
                            value={values.shipCost || ""}
                          />
                          {errors.shipCost && touched.shipCost ? (
                            <span className="text-danger error-message">
                              The ship cost must be a number.
                            </span>
                          ) : errorsServer.shipCost ? (
                            <span className="text-danger error-message">
                              {errorsServer.shipCost}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>Shipped</label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder="Shipped"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="Shipped"
                            value={values.Shipped || ""}
                          />
                          {errors.Shipped && touched.Shipped ? (
                            <span className="text-danger error-message">
                              {errors.Shipped}
                            </span>
                          ) : errorsServer.Shipped ? (
                            <span className="text-danger error-message">
                              {errorsServer.Shipped}
                            </span>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>Received</label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder="Received"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="Received"
                            value={values.Received || ""}
                          />
                          {errors.Received && touched.Received ? (
                            <span className="text-danger error-message">
                              {errors.Received}
                            </span>
                          ) : errorsServer.Received ? (
                            <span className="text-danger error-message">
                              {errorsServer.Received}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>Fulfilled</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Fulfilled"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="Fulfilled"
                            value={values.Fulfilled || ""}
                          />
                          {errors.Fulfilled && touched.Fulfilled ? (
                            <span className="text-danger error-message">
                              {errors.Fulfilled}
                            </span>
                          ) : errorsServer.Fulfilled ? (
                            <span className="text-danger error-message">
                              {errorsServer.Fulfilled}
                            </span>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>
                            First Name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="First Name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="First"
                            value={values.First || ""}
                          />
                          {errors.First && touched.First ? (
                            <span className="text-danger error-message">
                              {errors.First}
                            </span>
                          ) : errorsServer.First ? (
                            <span className="text-danger error-message">
                              {errorsServer.First}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>
                            Last Name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Last Name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="Last"
                            value={values.Last || ""}
                          />
                          {errors.Last && touched.Last ? (
                            <span className="text-danger error-message">
                              {errors.Last}
                            </span>
                          ) : errorsServer.Last ? (
                            <span className="text-danger error-message">
                              {errorsServer.Last}
                            </span>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>Street</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Street"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="Street"
                            value={values.Street || ""}
                          />
                          {errors.Street && touched.Street ? (
                            <span className="text-danger error-message">
                              {errors.Street}
                            </span>
                          ) : errorsServer.Street ? (
                            <span className="text-danger error-message">
                              {errorsServer.Street}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>
                            City <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="City"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="City"
                            value={values.City || ""}
                          />
                          {errors.City && touched.City ? (
                            <span className="text-danger error-message">
                              {errors.City}
                            </span>
                          ) : errorsServer.City ? (
                            <span className="text-danger error-message">
                              {errorsServer.City}
                            </span>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>
                            State <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="State"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="State"
                            value={values.State || ""}
                          />
                          {errors.State && touched.State ? (
                            <span className="text-danger error-message">
                              {errors.State}
                            </span>
                          ) : errorsServer.State ? (
                            <span className="text-danger error-message">
                              {errorsServer.State}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>
                            Zip <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Zip"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="Zip"
                            value={values.Zip || ""}
                          />
                          {errors.Zip && touched.Zip ? (
                            <span className="text-danger error-message">
                              {errors.Zip}
                            </span>
                          ) : errorsServer.Zip ? (
                            <span className="text-danger error-message">
                              {errorsServer.Zip}
                            </span>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>Begin Redemption</label>
                          <input
                            type="date"
                            className="form-control"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="issuance"
                            value={values.issuance || ""}
                          />
                          {errors.issuance && touched.issuance ? (
                            <span className="text-danger error-message">
                              {errors.issuance}
                            </span>
                          ) : errorsServer.issuance ? (
                            <span className="text-danger error-message">
                              {errorsServer.issuance}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>Expiration</label>
                          <input
                            type="date"
                            className="form-control"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="Expiration"
                            value={values.Expiration || ""}
                          />
                          <span className="text-danger">
                            (MM/DD/YYYY) eg : 12/25/2014
                          </span>
                          {errors.Expiration && touched.Expiration ? (
                            <span className="text-danger error-message">
                              {errors.Expiration}
                            </span>
                          ) : errorsServer.Expiration ? (
                            <span className="text-danger error-message">
                              {errorsServer.Expiration}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field-box mb-md-0">
                          <label>Certificate Status</label>
                          <input
                            type="text"
                            className={`form-control text-capitalize ${
                              values.CertiStatus === "active" ||
                              values.CertiStatus === "Valid"
                                ? "text-success"
                                : values.CertiStatus === "used"
                                ? "text-primary"
                                : values.CertiStatus === "expired" ||
                                  values.CertiStatus === "archive"
                                ? "text-danger"
                                : ""
                            }`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="CertiStatus"
                            value={values.CertiStatus || ""}
                            readOnly={true}
                          />
                          {errors.CertiStatus && touched.CertiStatus ? (
                            <span className="text-danger error-message">
                              {errors.CertiStatus}
                            </span>
                          ) : errorsServer.CertiStatus ? (
                            <span className="text-danger error-message">
                              {errorsServer.CertiStatus}
                            </span>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="input-field-box ">
                          <label>Choice 1</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Choice 1"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="choice1"
                            value={values.choice1 || ""}
                          />
                          {errors.choice1 && touched.choice1 ? (
                            <span className="text-danger error-message">
                              {errors.choice1}
                            </span>
                          ) : errorsServer.choice1 ? (
                            <span className="text-danger error-message">
                              {errorsServer.choice1}
                            </span>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="input-field-box ">
                          <label>Choice 2</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Choice 2"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="choice2"
                            value={values.choice2 || ""}
                          />
                          {errors.choice2 && touched.choice2 ? (
                            <span className="text-danger">
                              {errors.choice2}
                            </span>
                          ) : errorsServer.choice2 ? (
                            <span className="text-danger">
                              {errorsServer.choice2}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field-box ">
                          <label>Choice 3</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Choice 3"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="choice3"
                            value={values.choice3 || ""}
                          />
                          {errors.choice3 && touched.choice3 ? (
                            <span className="text-danger">
                              {errors.choice3}
                            </span>
                          ) : errorsServer.choice3 ? (
                            <span className="text-danger">
                              {errorsServer.choice3}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field-box ">
                          <label>Choice 4</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Choice 4"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="choice4"
                            value={values.choice4 || ""}
                          />
                          {errors.choice4 && touched.choice4 ? (
                            <span className="text-danger">
                              {errors.choice4}
                            </span>
                          ) : errorsServer.choice4 ? (
                            <span className="text-danger">
                              {errorsServer.choice4}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field-box ">
                          <label>Choice 5</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Choice 5"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="choice5"
                            value={values.choice5 || ""}
                          />
                          {errors.choice5 && touched.choice5 ? (
                            <span className="text-danger">
                              {errors.choice5}
                            </span>
                          ) : errorsServer.choice5 ? (
                            <span className="text-danger">
                              {errorsServer.choice5}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field-box ">
                          <label>Choice 6</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Choice 6"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="choice6"
                            value={values.choice6 || ""}
                          />
                          {errors.choice6 && touched.choice6 ? (
                            <span className="text-danger">
                              {errors.choice6}
                            </span>
                          ) : errorsServer.choice6 ? (
                            <span className="text-danger">
                              {errorsServer.choice6}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field-box ">
                          <label>Choice 7</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Choice 7"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="choice7"
                            value={values.choice7 || ""}
                          />
                          {errors.choice7 && touched.choice7 ? (
                            <span className="text-danger">
                              {errors.choice7}
                            </span>
                          ) : errorsServer.choice7 ? (
                            <span className="text-danger">
                              {errorsServer.choice7}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field-box ">
                          <label>Choice 8</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Choice 8"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="choice8"
                            value={values.choice8 || ""}
                          />
                          {errors.choice8 && touched.choice8 ? (
                            <span className="text-danger">
                              {errors.choice8}
                            </span>
                          ) : errorsServer.choice8 ? (
                            <span className="text-danger">
                              {errorsServer.choice8}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field-box ">
                          <label>Choice 9</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="choice 9"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="choice9"
                            value={values.choice9 || ""}
                          />
                          {errors.choice9 && touched.choice9 ? (
                            <span className="text-danger">
                              {errors.choice9}
                            </span>
                          ) : errorsServer.choice9 ? (
                            <span className="text-danger">
                              {errorsServer.choice9}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field-box ">
                          <label>Choice 10</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Choice 10"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="choice10"
                            value={values.choice10 || ""}
                          />
                          {errors.choice10 && touched.choice10 ? (
                            <span className="text-danger">
                              {errors.choice10}
                            </span>
                          ) : errorsServer.choice10 ? (
                            <span className="text-danger">
                              {errorsServer.choice10}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field-box ">
                          <label>Choice 11</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Choice 11"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="choice11"
                            value={values.choice11 || ""}
                          />
                          {errors.choice11 && touched.choice11 ? (
                            <span className="text-danger">
                              {errors.choice11}
                            </span>
                          ) : errorsServer.choice11 ? (
                            <span className="text-danger">
                              {errorsServer.choice11}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field-box ">
                          <label>Choice 12</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Choice 12"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="choice12"
                            value={values.choice12 || ""}
                          />
                          {errors.choice12 && touched.choice12 ? (
                            <span className="text-danger">
                              {errors.choice12}
                            </span>
                          ) : errorsServer.choice12 ? (
                            <span className="text-danger">
                              {errorsServer.choice12}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field-box ">
                          <label>Choice 13</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Choice 13"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="choice13"
                            value={values.choice13 || ""}
                          />
                          {errors.choice13 && touched.choice13 ? (
                            <span className="text-danger">
                              {errors.choice13}
                            </span>
                          ) : errorsServer.choice13 ? (
                            <span className="text-danger">
                              {errorsServer.choice13}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field-box ">
                          <label>Choice 14</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Choice 14"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="choice14"
                            value={values.choice14 || ""}
                          />
                          {errors.choice14 && touched.choice14 ? (
                            <span className="text-danger">
                              {errors.choice14}
                            </span>
                          ) : errorsServer.choice14 ? (
                            <span className="text-danger">
                              {errorsServer.choice14}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field-box ">
                          <label>Choice 15</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Choice 15"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="choice15"
                            value={values.choice15 || ""}
                          />
                          {errors.choice15 && touched.choice15 ? (
                            <span className="text-danger">
                              {errors.choice15}
                            </span>
                          ) : errorsServer.choice15 ? (
                            <span className="text-danger">
                              {errorsServer.choice15}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field-box ">
                          <label>Choice 16</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Choice 16"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="choice16"
                            value={values.choice16 || ""}
                          />
                          {errors.choice16 && touched.choice16 ? (
                            <span className="text-danger">
                              {errors.choice16}
                            </span>
                          ) : errorsServer.choice16 ? (
                            <span className="text-danger">
                              {errorsServer.choice16}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field-box ">
                          <label>Choice 17</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Choice 17"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="choice17"
                            value={values.choice17 || ""}
                          />
                          {errors.choice17 && touched.choice17 ? (
                            <span className="text-danger">
                              {errors.choice17}
                            </span>
                          ) : errorsServer.choice17 ? (
                            <span className="text-danger">
                              {errorsServer.choice17}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field-box ">
                          <label>Choice 18</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Choice 18"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="choice18"
                            value={values.choice18 || ""}
                          />
                          {errors.choice18 && touched.choice18 ? (
                            <span className="text-danger">
                              {errors.choice18}
                            </span>
                          ) : errorsServer.choice18 ? (
                            <span className="text-danger">
                              {errorsServer.choice18}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field-box ">
                          <label>Choice 19</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Choice 19"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="choice19"
                            value={values.choice19 || ""}
                          />
                          {errors.choice19 && touched.choice19 ? (
                            <span className="text-danger">
                              {errors.choice19}
                            </span>
                          ) : errorsServer.choice19 ? (
                            <span className="text-danger">
                              {errorsServer.choice19}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field-box ">
                          <label>Choice 20</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Choice 20"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="choice20"
                            value={values.choice20 || ""}
                          />
                          {errors.choice20 && touched.choice20 ? (
                            <span className="text-danger">
                              {errors.choice20}
                            </span>
                          ) : errorsServer.choice20 ? (
                            <span className="text-danger">
                              {errorsServer.choice20}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field-box ">
                          <label>Choice 21</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Choice 21"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="choice21"
                            value={values.choice21 || ""}
                          />
                          {errors.choice21 && touched.choice21 ? (
                            <span className="text-danger">
                              {errors.choice21}
                            </span>
                          ) : errorsServer.choice21 ? (
                            <span className="text-danger">
                              {errorsServer.choice21}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field-box ">
                          <label>Choice 22</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Choice 22"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="choice22"
                            value={values.choice22 || ""}
                          />
                          {errors.choice22 && touched.choice22 ? (
                            <span className="text-danger">
                              {errors.choice22}
                            </span>
                          ) : errorsServer.choice22 ? (
                            <span className="text-danger">
                              {errorsServer.choice22}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field-box ">
                          <label>Choice 23</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Choice 23"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="choice23"
                            value={values.choice23 || ""}
                          />
                          {errors.choice23 && touched.choice23 ? (
                            <span className="text-danger">
                              {errors.choice23}
                            </span>
                          ) : errorsServer.choice23 ? (
                            <span className="text-danger">
                              {errorsServer.choice23}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field-box ">
                          <label>Choice 24</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Choice 24"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="choice24"
                            value={values.choice24 || ""}
                          />
                          {errors.choice24 && touched.choice24 ? (
                            <span className="text-danger">
                              {errors.choice24}
                            </span>
                          ) : errorsServer.choice24 ? (
                            <span className="text-danger">
                              {errorsServer.choice24}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field-box ">
                          <label>Choice 25</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Choice 25"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="choice25"
                            value={values.choice25 || ""}
                          />
                          {errors.choice25 && touched.choice25 ? (
                            <span className="text-danger">
                              {errors.choice25}
                            </span>
                          ) : errorsServer.choice25 ? (
                            <span className="text-danger">
                              {errorsServer.choice25}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="card-footer">
                  <div className="col-12 text-center">
                    <button
                      className="btn btn-primary my-2 "
                      type="submit"
                      onClick={handleButtonClick}
                    >
                      Update Voucher
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </main>
      <Footer />
      {formSubmiting && (
        <ReactLoading
          type="spinningBubbles"
          color="#185791"
          className="bar-loader"
        />
      )}
    </div>
  );
}
