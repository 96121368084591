import React from "react";
import { Link, useLocation } from "react-router-dom";

import { Helmet } from "react-helmet";

export default function Sidebar() {
  const locate = useLocation();

  return (
    <>
      <Helmet>
        <title> {process.env.REACT_APP_API_SITE_TITLE} Admin</title>
      </Helmet>

      <div id="sidebar" className="collapse collapse-horizontal show ">
        <div
          id="sidebar-nav"
          className="list-group border-0 rounded-0 text-sm-start min-vh-100"
        >
          <Link
            to="/admin/dashboard"
            className={`list-group-item border-end-0 d-inline-block text-truncate ${
              locate.pathname === "/admin/dashboard" ? "active" : ""
            }`}
            data-bs-parent="#sidebar"
          >
            <i className="bi bi-house-fill"></i> <span> Dashboard</span>{" "}
          </Link>

          <a
            className={`list-group-item border-end-0 d-inline-block text-truncate has-arrow ${
              locate.pathname === "/admin/manage-document" ? "active" : ""
            }`}
            data-bs-toggle="collapse"
            href="#Document"
            role="button"
            aria-expanded={
              locate.pathname === "/admin/import-csv" ? "true" : "false"
            }
            aria-controls="Document"
          >
            <i className="bi bi-filetype-doc"></i> <span> Manage Document</span>{" "}
          </a>
          <div
            className={`collapse ${
              locate.pathname === "/admin/import-csv" ? "show" : ""
            }`}
            id="Document"
            data-bs-parent="#sidebar-nav"
          >
            <Link
              to="/admin/import-csv"
              className={`list-group-item border-end-0 d-inline-block text-truncate w-100 ${
                locate.pathname === "/admin/import-csv" ? "active" : ""
              }`}
              data-bs-parent="#sidebar"
            >
              <span> Import File</span>{" "}
            </Link>
          </div>

          <a
            className={`list-group-item border-end-0 d-inline-block text-truncate has-arrow ${
              locate.pathname === "/admin/user-uploads" ? "active" : ""
            }`}
            data-bs-toggle="collapse"
            href="#Gifts"
            role="button"
            aria-expanded={
              locate.pathname === "/admin/user-uploads" ? "true" : "false"
            }
            aria-controls="Gifts"
          >
            <i className="bi bi-journal-album"></i>{" "}
            <span> Uploaded Docs History</span>{" "}
          </a>
          <div
            className={`collapse ${
              locate.pathname === "/admin/user-uploads" ? "show" : ""
            }`}
            id="Gifts"
            data-bs-parent="#sidebar-nav"
          >
            <Link
              to="/admin/user-uploads"
              className={`list-group-item border-end-0 d-inline-block text-truncate w-100 ${
                locate.pathname === "/admin/user-uploads" ? "active" : ""
              }`}
              data-bs-parent="#sidebar"
            >
              <span> View Uploaded Files</span>{" "}
            </Link>
          </div>

          <a
            className={`list-group-item border-end-0 d-inline-block text-truncate has-arrow ${
              locate.pathname === "/admin/vouchers" ||
              locate.pathname === "/admin/used-vouchers" ||
              locate.pathname === "/admin/cancelled-vouchers"
                ? "active"
                : ""
            }`}
            data-bs-toggle="collapse"
            href="#vouchers"
            role="button"
            aria-expanded={
              locate.pathname === "/admin/vouchers" ||
              locate.pathname === "/admin/used-vouchers" ||
              locate.pathname === "/admin/cancelled-vouchers"
                ? "true"
                : "false"
            }
            aria-controls="vouchers"
          >
            <i className="bi bi-card-heading"></i>{" "}
            <span> Manage Certificates</span>{" "}
          </a>
          <div
            className={`collapse ${
              locate.pathname === "/admin/vouchers" ||
              locate.pathname === "/admin/used-vouchers" ||
              locate.pathname === "/admin/cancelled-vouchers"
                ? "show"
                : ""
            }`}
            id="vouchers"
            data-bs-parent="#sidebar-nav"
          >
            <Link
              to="/admin/vouchers"
              className={`list-group-item border-end-0 d-inline-block text-truncate w-100 ${
                locate.pathname === "/admin/vouchers" ? "active" : ""
              }`}
              data-bs-parent="#sidebar"
            >
              <span> Show Assigned Certificates</span>{" "}
            </Link>
            <Link
              to="/admin/used-vouchers"
              className={`list-group-item border-end-0 d-inline-block text-truncate w-100 ${
                locate.pathname === "/admin/used-vouchers" ? "active" : ""
              }`}
              data-bs-parent="#sidebar"
            >
              <span> Show Used Certificates</span>{" "}
            </Link>
            <Link
              to="/admin/cancelled-vouchers"
              className={`list-group-item border-end-0 d-inline-block text-truncate w-100 ${
                locate.pathname === "/admin/cancelled-vouchers" ? "active" : ""
              }`}
              data-bs-parent="#sidebar"
            >
              <span> Show Cancelled Certificates</span>{" "}
            </Link>
          </div>

          <a
            className={`list-group-item border-end-0 d-inline-block text-truncate has-arrow ${
              locate.pathname === "/admin/view-user" ? "active" : ""
            }`}
            data-bs-toggle="collapse"
            href="#orders"
            role="button"
            aria-expanded={
              locate.pathname === "/admin/view-user" ? "true" : "false"
            }
            aria-controls="orders"
          >
            <i className="bi bi-people"></i> <span> Manage Users</span>{" "}
          </a>
          <div
            className={`collapse ${
              locate.pathname === "/admin/view-user" ? "show" : ""
            }`}
            id="orders"
            data-bs-parent="#sidebar-nav"
          >
            <Link
              to="/admin/view-user"
              className={`list-group-item border-end-0 d-inline-block text-truncate w-100 ${
                locate.pathname === "/admin/view-user" ? "active" : ""
              }`}
              data-bs-parent="#sidebar"
            >
              <span> View All Users</span>{" "}
            </Link>
          </div>

          <a
            className={`list-group-item border-end-0 d-inline-block text-truncate has-arrow ${
              locate.pathname === "/admin/manage-job" ? "active" : ""
            }`}
            data-bs-toggle="collapse"
            href="#job"
            role="button"
            aria-expanded={
              locate.pathname === "/admin/manage-job" ? "true" : "false"
            }
            aria-controls="job"
          >
            <i className="bi bi-list-task"></i> <span> Manage Jobs</span>{" "}
          </a>
          <div
            className={`collapse ${
              locate.pathname === "/admin/manage-job" ? "show" : ""
            }`}
            id="job"
            data-bs-parent="#sidebar-nav"
          >
            <Link
              to="/admin/manage-job"
              className={`list-group-item border-end-0 d-inline-block text-truncate w-100 ${
                locate.pathname === "/admin/manage-job" ? "active" : ""
              }`}
              data-bs-parent="#sidebar"
            >
              <span> View All Jobs</span>{" "}
            </Link>
          </div>

          <a
            className={`list-group-item border-end-0 d-inline-block text-truncate has-arrow ${
              locate.pathname === "/admin/manage-website" ||
              locate.pathname === "/admin/manage-newsletter"
                ? "active"
                : ""
            }`}
            data-bs-toggle="collapse"
            href="#Website"
            role="button"
            aria-expanded={
              locate.pathname === "/admin/manage-website" ||
              locate.pathname === "/admin/manage-newsletter"
                ? "true"
                : "false"
            }
            aria-controls="Website"
          >
            <i className="bi bi-globe"></i> <span> Manage E-Voucher</span>{" "}
          </a>
          <div
            className={`collapse ${
              locate.pathname === "/admin/manage-website" ||
              locate.pathname === "/admin/manage-newsletter"
                ? "show"
                : ""
            }`}
            id="Website"
            data-bs-parent="#sidebar-nav"
          >
            <Link
              to="/admin/manage-website"
              className={`list-group-item border-end-0 d-inline-block text-truncate w-100 ${
                locate.pathname === "/admin/manage-website" ? "active" : ""
              }`}
              data-bs-parent="#sidebar"
            >
              <span> Manage Website</span>{" "}
            </Link>
            <Link
              to="/admin/manage-newsletter"
              className={`list-group-item border-end-0 d-inline-block text-truncate w-100 ${
                locate.pathname === "/admin/manage-newsletter" ? "active" : ""
              }`}
              data-bs-parent="#sidebar"
            >
              <span>View E-Voucher</span>{" "}
            </Link>
          </div>

          <a
            className={`list-group-item border-end-0 d-inline-block text-truncate has-arrow ${
              locate.pathname === "/admin/add-email" ||
              locate.pathname === "/admin/manage-email"
                ? "active"
                : ""
            }`}
            data-bs-toggle="collapse"
            href="#email"
            role="button"
            aria-expanded={
              locate.pathname === "/admin/add-email" ||
              locate.pathname === "/admin/manage-email"
                ? "true"
                : "false"
            }
            aria-controls="email"
          >
            <i className="bi bi-envelope-at"></i> <span> Manage Email</span>{" "}
          </a>
          <div
            className={`collapse ${
              locate.pathname === "/admin/add-email" ||
              locate.pathname === "/admin/manage-email"
                ? "show"
                : ""
            }`}
            id="email"
            data-bs-parent="#sidebar-nav"
          >
            <Link
              to="/admin/add-email"
              className={`list-group-item border-end-0 d-inline-block text-truncate w-100 ${
                locate.pathname === "/admin/add-email" ? "active" : ""
              }`}
              data-bs-parent="#sidebar"
            >
              <span> Add Email</span>{" "}
            </Link>
            <Link
              to="/admin/manage-email"
              className={`list-group-item border-end-0 d-inline-block text-truncate w-100 ${
                locate.pathname === "/admin/manage-email" ? "active" : ""
              }`}
              data-bs-parent="#sidebar"
            >
              <span> View Email</span>{" "}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
