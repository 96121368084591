import React from "react";

import Footer from "./Footer";

import { Icon } from "@iconify/react";

import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import secureLocalStorage from "react-secure-storage";
import { useState } from "react";
import Header from "./Header";

function Thankyou() {
  const navigate = useNavigate();

  var redemptionForm = secureLocalStorage.getItem("UserForm");

  var redemptionObject = redemptionForm ? JSON.parse(redemptionForm) : false;

  const isVoucher = localStorage.getItem("isVoucher");
  const Voucher = localStorage.getItem("voucher");
  const [showPage, setShowPage] = useState(false);
  useEffect(() => {
    if (
      isVoucher === "false" ||
      Voucher === "undefined" ||
      isVoucher === false ||
      Voucher === undefined ||
      isVoucher === "null" ||
      Voucher === "null" ||
      isVoucher === null ||
      Voucher === null
    ) {
      localStorage.setItem("isVoucher", false);
      navigate("/");
    } else {
      localStorage.setItem("returnpage", false);
      localStorage.removeItem("voucher");
      setShowPage(true);
    }
    // eslint-disable-next-line
  }, []);
  return (
    <>
      {!showPage && <div className="showPage"></div>}
      <Header />
      <div className="container-fluid">
        <div className="white-box thank">
          <h1 className="text-center">Thank You! </h1>
          <div className="mark-asignm">
            <Icon icon="zmdi:assignment-check" />
          </div>

          <p className="thank-txt">
            Thank You! Your order has been received. <br />
            Your choice is {redemptionObject.card}
            <br /> Your Travel Reservation form will be mailed to you.
            <span>
              <i className="zmdi zmdi-time"></i> Please allow 2-3 weeks for
              delivery
            </span>
          </p>
          <div className="footer-info">
            <ul className="info-list clearfix">
              <li>
                <a href="tel:800-585-9806">
                  <span className="phone-icon">
                    <Icon icon="fluent:call-48-filled" />
                  </span>
                  800-585-9806
                </a>
              </li>
              <li>
                <a href="mailto:customercare@eliterewards.biz">
                  <Icon icon="clarity:email-solid" />
                  customercare@eliterewards.biz
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default Thankyou;
