import secureLocalStorage from "react-secure-storage";

// secureLocalStorage.setItem("acessTokens", JSON.stringify(acessTokens));

let retrievedObject = JSON.parse(secureLocalStorage.getItem("acessTokens"));

const adminAccessToken = retrievedObject?.adminAccessToken;
const admin_id = retrievedObject?.admin_id;
const user_type = retrievedObject?.user_type;

if (!retrievedObject) {
  retrievedObject = {};
}

const initialState = {
  adminAccessToken: adminAccessToken ? adminAccessToken : null,
  admin_id: admin_id ? admin_id : null,
  user_type: user_type ? user_type : null,
};

const reducer = (state = initialState, action) => {
  if (action.type === "adminLogin") {
    retrievedObject.adminAccessToken = action.payload.adminAccessToken;
    retrievedObject.admin_id = action.payload.admin_id;
    retrievedObject.user_type = action.payload.user_type;
    secureLocalStorage.setItem("acessTokens", JSON.stringify(retrievedObject));

    return {
      ...state,
      adminAccessToken: action.payload.adminAccessToken,
      admin_id: action.payload.admin_id,
      user_type: action.payload.user_type,
    };
  } else if (action.type === "adminLogOut") {
    delete retrievedObject?.admin_id;
    delete retrievedObject?.user_type;
    delete retrievedObject?.adminAccessToken;
    if (Object.keys(retrievedObject).length !== 0) {
      secureLocalStorage.setItem(
        "acessTokens",
        JSON.stringify(retrievedObject)
      );
    } else {
      secureLocalStorage.removeItem("acessTokens");
    }

    return {
      ...state,
      adminAccessToken: null,
      admin_id: null,
      user_type: null,
    };
  } else {
    return state;
  }
};
export default reducer;
