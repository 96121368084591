import React from "react";
import Header from "./Header";
import Footer from "./Footer";

export default function PrivacyPolicy() {
  return (
    <>
      <Header />
      <div className="container-fluid">
        <div id="main-registration-container">
          <div id="register">
            <div className="white-box">
              <h1>PRIVACY STATEMENT</h1>

              <div className="p-policy">
                <h4>ELITE REWARDS PRIVACY STATEMENT</h4>
                <b>Site Security Features</b>
                <p>
                  Elite Rewards (ER) realizes how important security is to our
                  clients and to their participants, so we’ve taken a number of
                  steps to enhance the protection of personal or confidential
                  information sent to or from ER or in accessing information
                  resident at ER. This includes username, password, and any
                  debit card information. First, we require unique usernames and
                  password establishment that are not easily determined by
                  someone other than the intended participant. This requirement
                  protects personal information and access to personal earnings
                  that are available for redemption.
                </p>
                <b>SSL Technology</b>
                <p>
                  ER requires that a “secure session” is established, using
                  Secure Socket Layer (SSL) technology. This is done anytime a
                  participant supplies ER with personal or confidential
                  information in one of the secure areas of a website.
                </p>
                <p>
                  SSL technology creates a private conversation that only the
                  participant’s computer and ER can understand. The SSL
                  technology encodes information as it is being sent over the
                  Internet between the participant’s computer and ER’s system
                  helping to ensure that the transmitted information remains
                  confidential.
                </p>
                <p>
                  The use of SSL requires two components: and SSL-compatible
                  browser and a Web server to perform “key-exchange” that
                  establishes a secure connection to ER web servers.
                </p>

                <b>Browser Compliance</b>
                <p>
                  Participants and client will need a browser with SSL
                  capabilities. Examples of SSL browsers include, Microsoft’s
                  Internet Explorer, Firefox, Chrome and Safari. If a
                  participant does not have a browser with SSL capabilities, the
                  participant can download an SSL browser from the above
                  mentioned SSL browser list and will be required in order to
                  securely access personal or confidential information via the
                  Internet. ER codes sites to current browser version minus 1.
                  ER recommends the use of the latest browser versions
                  available. Accessing secure online areas requires SSL capable
                  browsers due to security concerns.
                </p>
                <b>ISP Compliance</b>
                <p>
                  Nearly all Internet Service Providers (ISPs) automatically
                  enable the SSL session described above. If a participant or a
                  client contact uses their company's internal connection to
                  access the Internet they may find they cannot access the ER
                  secure pages with an SSL browser described above, the company
                  may be blocking access via a "firewall." The company’s
                  Internet access systems administrator would have to be
                  contacted for further details on Internet access.
                </p>
                <b>Cookies</b>
                <p>
                  A participant or client must have enabled cookies on their
                  browser in order to access confidential information. If they
                  have chosen to disable cookies on their browser, they will not
                  be able to access confidential information.
                </p>

                <b>Third Party Use</b>
                <p>
                  ER does not sell, trade or rent personal information to third
                  parties. We do, however, share your shipping information with
                  our suppliers for the sole purpose of delivering your
                  redemption items.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
