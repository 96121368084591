import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Link, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { useEffect } from "react";
import { Icon } from "@iconify/react";

function CertdDtails(props) {
  const isVoucher = localStorage.getItem("cert_details");
  const navigate = useNavigate();
  var cert_details = secureLocalStorage.getItem("cert_details");
  var RedemObject = JSON.parse(cert_details);

  useEffect(() => {
    if (isVoucher !== "true" || isVoucher === undefined || isVoucher === null) {
      navigate("/");
    } else {
      localStorage.setItem("cert_details", false);
    }
  }, []);
  return (
    <>
      <Header />
      <div className="container-fluid">
        {/* <div className="header_lower">CITY Furniture and Elite Rewards are proud supporters of the American Heart Association</div> */}
        <div className="white-box">
          <h1>{process.env.REACT_APP_API_SITE_TITLE} Site</h1>
          <hr />
          <h2>Contact Information</h2>
          <hr />
          <form className="form-outer register clearfix">
            <div className="row">
              <div className="col-md-6">
                <label>
                  <strong>First Name </strong>
                  <br />
                  {RedemObject?.fname ? RedemObject.fname : ""}
                </label>
              </div>
              <div className="col-md-6">
                <label>
                  <strong>Last Name</strong>
                  <br />
                  {RedemObject?.lname ? RedemObject.lname : ""}
                </label>
              </div>
              <div className="clearfix"></div>
              <div className="col-md-6">
                <label>
                  <strong>Address</strong>
                  <br />
                  {RedemObject?.address ? RedemObject.address : "N/A"}
                </label>
              </div>
              {/* <div className="col-md-6">
                <label>
                  <strong>Address2</strong>
                  <br />
                  {RedemObject?.address2 ? RedemObject.address2 : ""}
                </label>
              </div> */}
              <div className="col-md-6">
                <label>
                  <strong>City </strong>
                  <br />
                  {RedemObject?.city ? RedemObject.city : ""}
                </label>
              </div>
              <div className="clearfix"></div>
              <div className="col-md-6">
                <label>
                  <strong>State </strong>
                  <br />
                  {RedemObject?.state ? RedemObject.state : ""}
                </label>
              </div>
              <div className="col-md-6">
                <label>
                  <strong>Zip Code</strong>
                  <br />
                  {RedemObject?.zip ? RedemObject.zip : ""}
                </label>
              </div>{" "}
              <div className="clearfix"></div>
              <div className="col-md-6">
                <label>
                  <strong>Phone No </strong>
                  <br />
                  {RedemObject?.phone ? RedemObject.phone : ""}
                </label>
              </div>
              <div className="col-md-6">
                <label>
                  <strong>Email </strong>
                  <br />
                  {RedemObject?.email ? RedemObject.email : ""}
                </label>
              </div>
              <div className="col-md-12">
                <hr />
                <h2>Trip</h2>
                <hr />
                <div class="check-boexs cen">
                  <label className="fw-medium">
                    {RedemObject?.cardName ? RedemObject.cardName : ""}
                  </label>
                </div>
              </div>
            </div>
            <Link to="/" className="btn btn-md btn-primary ">
              Go Back
            </Link>
          </form>
          <div className="footer-info">
            <ul className="info-list clearfix">
              <li>
                <a href="tel:800-585-9806">
                  <span className="phone-icon">
                    <Icon icon="fluent:call-48-filled" />
                  </span>
                  800-585-9806
                </a>
              </li>
              <li>
                <a href="mailto:customercare@eliterewards.biz">
                  <Icon icon="clarity:email-solid" />
                  customercare@eliterewards.biz
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default CertdDtails;
