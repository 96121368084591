import React, { useEffect } from "react";
import Sidebar from "../includes/Sidebar";
import Footer from "../includes/Footer";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useState } from "react";
import AdminListService from "../../../services/admin-list.service";

import MainHeading from "../includes/MainHeading";
import Skeleton from "react-loading-skeleton";
import formatThousands from "format-thousands";
import Swal from "sweetalert2";

export default function Dashboard() {
  const [loading, setLoading] = useState(false);
  const [tableLoader, settableLoader] = useState(false);
  const [counters, setCounters] = useState([]);
  const [tableData, settableData] = useState([]);

  const state = useSelector((state) => state.stateVals);
  const { adminAccessToken, admin_id } = state;
  const navigate = useNavigate();

  const usersList = async () => {
    settableLoader(true);
    try {
      const responce = await AdminListService.latestOrder();

      const res = responce.data.response;
      const results = [];
      res.map((value) => {
        return results.push({
          userName: value.fname + " " + value.lname,
          email: value.email,
          status: value.status,
          voucher_number: value.voucher_number,
          id: value.id,
          archive: value.archive,
        });
      });

      settableData([...results]);

      settableLoader(false);
    } catch (err) {
      console.log(err);
      settableLoader(false);
      settableData([]);
    }
  };

  useEffect(() => {
    if (adminAccessToken) {
      const counters = async (values) => {
        setLoading(true);
        try {
          const response = await AdminListService.getCounts(values);

          setCounters(response.data);
          setLoading(false);
        } catch (err) {
          console.log(err);
          setLoading(false);

          if (
            err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
          ) {
            toast.error(err?.response?.data?.message, {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          } else {
            toast.error("Something went wrong, try again", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
        }
      };
      counters();

      usersList();
    } else {
      navigate("/admin/login", { replace: true });
    }

    // eslint-disable-next-line
  }, []);

  const delOrder = async (delId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete record",
      icon: "question",
      iconColor: "#e04f5d",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      confirmButtonText: "Yes",
      cancelButtonText: "Close",
      customClass: {
        container: "del-popup", // Apply your custom class to the container
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await AdminListService.deletSingleOrders(
            delId,
            admin_id
          );

          usersList();

          Swal.fire({
            icon: "success",
            title: "Deleted Successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
        } catch (err) {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            iconColor: "#e04f5d",
            confirmButtonColor: "#e72e2e",
            text: "Something went wrong!",
          });
        }
      }
    });
  };

  const Table = ({ data }) => {
    return (
      <div className="table-responsive">
        <table className="text-nowrap mb-0 table">
          <thead className="table-light">
            <tr>
              <th scope="col">No</th>

              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Certificate</th>
              <th scope="col">Certificate Status</th>

              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {data?.length ? (
              data?.map((el, index) => (
                <tr key={index}>
                  <td className="align-middle">{index + 1} </td>
                  <td className="align-middle">{el.userName} </td>
                  <td className="align-middle">{el.email} </td>
                  <td className="align-middle">{el.voucher_number} </td>
                  <td className="align-middle">
                    <span
                      className={`text-control font-17 text-capitalize ${
                        el.status === "active" || el.status === "Valid"
                          ? "text-success"
                          : el.status === "used"
                          ? "text-primary"
                          : el.status === "expired" || el.status === "archive"
                          ? "text-danger"
                          : ""
                      }`}
                    >
                      {el.status !== "" ? el.status : "N/A"}
                    </span>
                  </td>

                  <td className="align-middle ">
                    <div className="d-flex align-items-center">
                      <Link
                        to={`/admin/edit-user/${el.id}`}
                        className="btn btn-success btn-sm btn-icon-text me-3"
                      >
                        Edit <i className="bi bi-pencil-square"></i>
                      </Link>
                      <button
                        type="button"
                        className="btn btn-danger btn-sm btn-icon-text"
                        onClick={() => {
                          delOrder(el.id);
                        }}
                      >
                        Delete <i className="bi bi-x-circle"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <Sidebar />
          <MainHeading heading="Dashboard" />

          <div className="row">
            <div className="mt-4 col-xl-3 col-lg-6 col-md-12 col-12">
              <div className="card dashboard-cards">
                <div className="card-body">
                  {loading ? (
                    <Skeleton count={5} height="18.3px" />
                  ) : (
                    <>
                      <div className="d-flex justify-content-between align-items-center mb-3">
                        <div>
                          <h4 className="mb-0">Jobs</h4>
                        </div>
                        <div className="icon-shape">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-list-task"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fillRule="evenodd"
                              d="M2 2.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5V3a.5.5 0 0 0-.5-.5zM3 3H2v1h1z"
                            />
                            <path d="M5 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5M5.5 7a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1zm0 4a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1z" />
                            <path
                              fillRule="evenodd"
                              d="M1.5 7a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5zM2 7h1v1H2zm0 3.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm1 .5H2v1h1z"
                            />
                          </svg>
                        </div>
                      </div>
                      <div>
                        <h1 className="fw-bold">
                          {formatThousands(
                            counters?.jobs ? counters?.jobs : 0,
                            { separator: "," }
                          )}
                        </h1>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="mt-4 col-xl-3 col-lg-6 col-md-12 col-12">
              <div className="card dashboard-cards">
                <div className="card-body">
                  {loading ? (
                    <Skeleton count={5} height="18.3px" />
                  ) : (
                    <>
                      <div className="d-flex justify-content-between align-items-center mb-3">
                        <div>
                          <h4 className="mb-0">Users</h4>
                        </div>
                        <div className="icon-shape ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-people"
                            viewBox="0 0 16 16"
                          >
                            <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022ZM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4" />
                          </svg>
                        </div>
                      </div>
                      <div>
                        <h1 className="fw-bold">
                          {" "}
                          {formatThousands(
                            counters?.users ? counters?.users : 0,
                            { separator: "," }
                          )}
                        </h1>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-4 col-xl-3 col-lg-6 col-md-12 col-12">
              <div className="card dashboard-cards">
                <div className="card-body">
                  {loading ? (
                    <Skeleton count={5} height="18.3px" />
                  ) : (
                    <>
                      <div className="d-flex justify-content-between align-items-center mb-3">
                        <div>
                          <h4 className="mb-0">Uploaded Files</h4>
                        </div>
                        <div className="icon-shape ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-filetype-doc"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fillRule="evenodd"
                              d="M14 4.5V14a2 2 0 0 1-2 2v-1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zm-7.839 9.166v.522c0 .256-.039.47-.117.641a.861.861 0 0 1-.322.387.877.877 0 0 1-.469.126.883.883 0 0 1-.471-.126.868.868 0 0 1-.32-.386 1.55 1.55 0 0 1-.117-.642v-.522c0-.257.04-.471.117-.641a.868.868 0 0 1 .32-.387.868.868 0 0 1 .471-.129c.176 0 .332.043.469.13a.861.861 0 0 1 .322.386c.078.17.117.384.117.641Zm.803.519v-.513c0-.377-.068-.7-.205-.972a1.46 1.46 0 0 0-.589-.63c-.254-.147-.56-.22-.917-.22-.355 0-.662.073-.92.22a1.441 1.441 0 0 0-.589.627c-.136.271-.205.596-.205.975v.513c0 .375.069.7.205.973.137.271.333.48.59.627.257.144.564.216.92.216.357 0 .662-.072.916-.216.256-.147.452-.356.59-.627.136-.274.204-.598.204-.973ZM0 11.926v4h1.459c.402 0 .735-.08.999-.238a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.59-.68c-.263-.156-.598-.234-1.004-.234H0Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.141 1.141 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082H.79V12.57Zm7.422.483a1.732 1.732 0 0 0-.103.633v.495c0 .246.034.455.103.627a.834.834 0 0 0 .298.393.845.845 0 0 0 .478.131.868.868 0 0 0 .401-.088.699.699 0 0 0 .273-.248.8.8 0 0 0 .117-.364h.765v.076a1.268 1.268 0 0 1-.226.674c-.137.194-.32.345-.55.454a1.81 1.81 0 0 1-.786.164c-.36 0-.664-.072-.914-.216a1.424 1.424 0 0 1-.571-.627c-.13-.272-.194-.597-.194-.976v-.498c0-.379.066-.705.197-.978.13-.274.321-.485.571-.633.252-.149.556-.223.911-.223.219 0 .421.032.607.097.187.062.35.153.489.272a1.326 1.326 0 0 1 .466.964v.073H9.78a.85.85 0 0 0-.12-.38.7.7 0 0 0-.273-.261.802.802 0 0 0-.398-.097.814.814 0 0 0-.475.138.868.868 0 0 0-.301.398Z"
                            />
                          </svg>
                        </div>
                      </div>
                      <div>
                        <h1 className="fw-bold">
                          {formatThousands(
                            counters?.uploadFiles ? counters?.uploadFiles : 0,
                            { separator: "," }
                          )}
                        </h1>
                        {/* <p className="mb-0">
                          <span>
                            {formatThousands(
                              counters?.activeGift ? counters?.activeGift : 0,
                              { separator: "," }
                            )}
                          </span>{" "}
                          Active Gifts
                        </p> */}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-4 col-xl-3 col-lg-6 col-md-12 col-12">
              <div className="card dashboard-cards">
                <div className="card-body">
                  {loading ? (
                    <Skeleton count={5} height="18.3px" />
                  ) : (
                    <>
                      <div className="d-flex justify-content-between align-items-center mb-3">
                        <div>
                          <h4 className="mb-0">Assigned Certificates</h4>
                        </div>
                        <div className="icon-shape ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-badge-vo"
                            viewBox="0 0 16 16"
                          >
                            <path d="M4.508 11h1.429l1.99-5.999H6.61L5.277 9.708H5.22L3.875 5.001H2.5L4.508 11zM13.5 8.39v-.77c0-1.696-.962-2.733-2.566-2.733-1.604 0-2.571 1.029-2.571 2.734v.769c0 1.691.967 2.724 2.57 2.724 1.605 0 2.567-1.033 2.567-2.724zm-1.204-.778v.782c0 1.156-.571 1.732-1.362 1.732-.796 0-1.363-.576-1.363-1.732v-.782c0-1.156.567-1.736 1.363-1.736.79 0 1.362.58 1.362 1.736z" />
                            <path d="M14 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12zM2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2z" />
                          </svg>
                        </div>
                      </div>
                      <div>
                        <h1 className="fw-bold">
                          {formatThousands(
                            counters?.assignedCertificates
                              ? counters?.assignedCertificates
                              : 0,
                            { separator: "," }
                          )}
                        </h1>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="mt-5 row">
            <div className="col-md-12 col-12">
              <div className="card dashboard-cards">
                <div className="bg-white  py-3 card-header">
                  <h4 className="mb-0">Latest Users</h4>
                </div>

                {tableLoader ? (
                  <div className="card-body">
                    <Skeleton count={11} height="50px" />
                  </div>
                ) : (
                  <Table data={tableData} />
                )}

                <div className="bg-white text-center card-footer">
                  <Link className="link-primary" to="/admin/view-user">
                    View All Users
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}
