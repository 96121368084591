import React from "react";
import $ from "jquery";
export default function ToTop() {
  const backToTop = () => {
    return (
      $("html, body").animate(
        {
          scrollTop: 0,
        },
        600
      ),
      !1
    );
  };

  return (
    <>
      <div className="overlay nav-toggle-icon"></div>

      <div className="back-to-top" onClick={backToTop}>
        <i className="bi bi-arrow-up top-arrow"></i>
      </div>
    </>
  );
}
