import React, { useMemo } from "react";
import Sidebar from "../includes/Sidebar";
import Footer from "../includes/Footer";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { useState } from "react";
import AdminListService from "../../../services/admin-list.service";

import MainHeading from "../includes/MainHeading";
import Skeleton from "react-loading-skeleton";
import Swal from "sweetalert2";
import Pagination from "react-js-pagination";
import { useFormik } from "formik";
import { quizSearch } from "../../../schema";
import moment from "moment";
import { toast } from "react-toastify";
import formatThousands from "format-thousands";

export default function ViewCanceledVouchers() {
  const [tableLoader, settableLoader] = useState(false);

  const [tableData, settableData] = useState([]);
  const [limit, setLimit] = useState("10");
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [years, setYears] = useState([]);
  const [currentDateTime, setCurrentDateTime] = useState("");

  const [currentPage, setCurrentPage] = useState(1);

  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("0");

  const state = useSelector((state) => state.stateVals);
  const { adminAccessToken, admin_id } = state;
  const navigate = useNavigate();

  const vouchersLists = async (page, limit, values, year) => {
    settableLoader(true);
    try {
      const responce = await AdminListService.getVouchers(
        page,
        limit,
        values,
        "cancelled",
        year
      );

      const res = responce?.data?.response?.response;

      const results = [];
      res.map((value) => {
        return results.push({
          address1: value.address,
          address2: value.address2,
          city: value.city,
          email: value.email,
          fname: value.fname,
          lname: value.lname,
          issuance: value.issuance,
          expiration: value.expiration,

          status: value.status,
          job: value.job,
          voucher: value.voucher,
          certificate: value.certificate,
          created_at: value.created_at,
          phone: value.phone,
          state: value.state,
          vocher: value.vocher,
          zip: value.zip,
          customer_id: value.customer_id,
          company: value.company,
          company_no: value.company_no,
          certificate_type: value.certificate_type,
          voucher_number: value.voucher_number,
          mail_status: value.mail_status,
          id: value.id,
          size:
            value.choice01 +
            " " +
            value.choice02 +
            " " +
            value.choice03 +
            " " +
            value.choice04 +
            " " +
            value.choice05 +
            " " +
            value.choice06 +
            " " +
            value.choice07 +
            " " +
            value.choice08 +
            " " +
            value.choice09 +
            " " +
            value.choice10 +
            " " +
            value.choice11 +
            " " +
            value.choice12 +
            " " +
            value.choice13 +
            " " +
            value.choice14 +
            " " +
            value.choice15 +
            " " +
            value.choice16 +
            " " +
            value.choice17 +
            " " +
            value.choice18 +
            " " +
            value.choice19 +
            " " +
            value.choice20 +
            " " +
            value.choice21 +
            " " +
            value.choice22 +
            " " +
            value.choice23 +
            " " +
            value.choice24 +
            " " +
            value.choice25,
        });
      });

      settableData([...results]);
      setTotalPages(responce?.data?.response?.total_pages);
      setTotalResults(responce?.data?.response?.records);
      setCurrentPage(responce?.data?.response?.current_page);
      setLimit(responce?.data?.response?.per_page);

      settableLoader(false);
    } catch (err) {
      console.log(err);
      settableLoader(false);
      settableData([]);
      setTotalPages("0");
      setTotalResults("0");
    }
  };

  useMemo(() => {
    if (adminAccessToken) {
      vouchersLists("1", limit, [], currentYear);
      const yearList = async () => {
        try {
          const responce = await AdminListService.getAllYears();

          const res = responce?.data?.response;

          const results = [];
          res.map((value) => {
            return results.push({
              year: value[0],
            });
          });

          setYears([...results]);
        } catch (err) {
          console.log(err);
          setYears([]);
        }
      };
      yearList();
      const updateTimestamp = () => {
        const timestampInSeconds = Math.floor(Date.now() / 1000);
        setCurrentDateTime(timestampInSeconds);
      };

      const intervalId = setInterval(updateTimestamp, 1000);

      return () => clearInterval(intervalId);
    } else {
      navigate("/admin/login", { replace: true });
    }

    // eslint-disable-next-line
  }, []);

  const delVoucher = async (delId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete record",
      icon: "question",
      iconColor: "#e04f5d",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      confirmButtonText: "Yes",
      cancelButtonText: "Close",
      customClass: {
        container: "del-popup", // Apply your custom class to the container
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await AdminListService.deletSingleVouchers(
            delId,
            admin_id
          );

          vouchersLists(currentPage, limit, values, currentYear);

          Swal.fire({
            icon: "success",
            title: "Deleted Successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
        } catch (err) {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            iconColor: "#e04f5d",
            confirmButtonColor: "#e72e2e",
            text: "Something went wrong!",
          });
        }
      }
    });
  };

  const archiveVoucher = async (order_id) => {
    settableLoader(true);
    try {
      const response = await AdminListService.voucherArchive(
        admin_id,
        order_id
      );

      toast.success(response?.data?.response, {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      settableLoader(false);
      setTimeout(() => {
        vouchersLists(currentPage, limit, values, currentYear);
      }, 1000);
    } catch (err) {
      console.log(err);
      settableLoader(false);

      if (
        err?.response?.data?.error &&
        typeof err?.response?.data?.error === "string"
      ) {
        toast.error(err?.response?.data?.error, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error("Something went wrong, try again", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,

      initialValues: {
        fieldtype: "",
        searchval: "",
      },
      validationSchema: quizSearch,
      onSubmit: (values) => {
        vouchersLists("1", limit, values, currentYear);
      },
    });

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <Sidebar />
          <MainHeading heading="Manage Your Redemption Codes" />

          <div className="mt-4 row">
            <div className="col-md-12 col-12">
              <div className="card dashboard-cards">
                <div className="bg-white  py-3 card-header">
                  <div className="row">
                    <div className="col-md-8">
                      <h4 className="mb-0">
                        Total Cancelled Voucher:{" "}
                        <span className="text-primary-color fw-bold">
                          {formatThousands(totalResults, {
                            separator: ",",
                          })}
                        </span>
                      </h4>
                    </div>
                    <div className="col-md-4 text-end">
                      <Link
                        to="/admin/archive-vouchers"
                        className="btn btn-success btn-sm btn-icon-text"
                      >
                        View Archived Voucher <i className="bi bi-archive"></i>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <form className="row" onSubmit={handleSubmit} noValidate>
                    <div className="col-6 ">
                      <a
                        className="btn btn-primary btn-sm btn-icon-text me-2"
                        href={`${process.env.REACT_APP_API_Link}export-vouchers/${currentYear}/all?time=${currentDateTime}`}
                      >
                        Export All Certificate{" "}
                        <i className="bi bi-filetype-exe"></i>
                      </a>
                      <a
                        className="btn btn-primary btn-sm btn-icon-text"
                        href={`${process.env.REACT_APP_API_Link}export-vouchers/${currentYear}/active?time=${currentDateTime}`}
                      >
                        Export Active Certificate{" "}
                        <i className="bi bi-filetype-exe"></i>
                      </a>
                    </div>
                    <div className="col-6 text-end">
                      <a
                        className="btn btn-primary btn-sm btn-icon-text me-2"
                        href={`${process.env.REACT_APP_API_Link}export-vouchers/${currentYear}/used?time=${currentDateTime}`}
                      >
                        Export Used Certificate{" "}
                        <i className="bi bi-filetype-exe"></i>
                      </a>
                      <a
                        className="btn btn-primary btn-sm btn-icon-text "
                        href={`${process.env.REACT_APP_API_Link}export-vouchers/${currentYear}/archive?time=${currentDateTime}`}
                      >
                        Export Archive Certificate{" "}
                        <i className="bi bi-filetype-exe"></i>
                      </a>
                    </div>
                    <div className="col-12">
                      <hr />
                    </div>
                    <div className="col-lg-3">
                      <div className="input-field-box mb-lg-0">
                        <label>Show Column</label>
                        <select
                          className={`form-select ${
                            errors.fieldtype && touched.fieldtype
                              ? "is-danger"
                              : ""
                          }`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="fieldtype"
                          value={values.fieldtype || ""}
                          required
                        >
                          <option value="">Select Column</option>
                          <option value="company"> Company</option>
                          <option value="job"> Job Number </option>
                          <option value="certificate">Certificate</option>
                          <option value="fname">First Name </option>
                          <option value="lname">Last Name </option>
                          <option value="address1">Address</option>
                          <option value="city">City</option>
                          <option value="state">State</option>
                          <option value="zip">Zip</option>
                          <option value="phone">Phone </option>
                          <option value="email">Email </option>
                          <option value="issuance">Begin Redemption </option>
                          <option value="expiration">Expiration </option>
                        </select>
                      </div>
                      {errors.fieldtype && touched.fieldtype ? (
                        <span className="text-danger">{errors.fieldtype}</span>
                      ) : null}
                    </div>

                    <div className="col-lg-5">
                      <div className="input-field-box mb-lg-0">
                        <label>Search</label>
                        <div className="input-group">
                          <input
                            type={
                              values.fieldtype === "issuance" ||
                              values.fieldtype === "expiration"
                                ? "date"
                                : "text"
                            }
                            className={`form-control ${
                              errors.searchval && touched.searchval
                                ? "is-danger"
                                : ""
                            }`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="searchval"
                            value={values.searchval || ""}
                            required
                          />

                          <button className="btn btn-primary " type="submit">
                            Search
                          </button>
                          <button
                            className="btn btn-primary"
                            type="reset"
                            onClick={() => window.location.reload(false)}
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                      {errors.searchval && touched.searchval ? (
                        <span className="text-danger">{errors.searchval}</span>
                      ) : null}
                    </div>
                    <div className="col-lg-2">
                      <div className="input-field-box mb-0">
                        <label>Show Entries By Year</label>
                        <select
                          className="form-select"
                          value={currentYear}
                          onChange={(e) => {
                            setCurrentYear(e.target.value);
                            vouchersLists("1", limit, values, e.target.value);
                          }}
                        >
                          {years.length
                            ? years.map((el, index) => (
                                <option value={el.year} key={index}>
                                  {el.year}
                                </option>
                              ))
                            : null}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <div className="input-field-box mb-0">
                        <label>Show Entries</label>
                        <select
                          className="form-select"
                          value={limit}
                          onChange={(e) =>
                            vouchersLists("1", e.target.value, [], currentYear)
                          }
                        >
                          <option value="10">10</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="250">250</option>
                          <option value="500">500</option>
                        </select>
                      </div>
                    </div>
                  </form>
                </div>
                {tableLoader ? (
                  <div className="card-body pt-0">
                    <Skeleton count={11} height="50px" />
                  </div>
                ) : (
                  <div className="table-responsive">
                    <table className="text-nowrap  table">
                      <thead className="table-light">
                        <tr>
                          <th scope="col">No</th>
                          <th scope="col" width="50px">
                            Job No
                          </th>

                          <th scope="col" width="50px">
                            Voucher
                          </th>

                          <th scope="col">Company Info</th>
                          <th scope="col">Basic Info</th>
                          <th scope="col">Date</th>
                          <th scope="col">Choices</th>
                          <th scope="col">Status</th>
                          <th scope="col">Email Status</th>

                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData.length ? (
                          tableData.map((el, index) => (
                            <tr key={index}>
                              <td className="align-middle">
                                {currentPage === 1
                                  ? index + 1
                                  : (currentPage - 1) * limit + index + 1}
                              </td>
                              <td className="align-middle">
                                <span className="text-control">{el.job}</span>
                              </td>
                              <td className="align-middle">
                                <span className="text-control">
                                  {el.voucher_number}
                                </span>
                              </td>
                              <td className="align-middle">
                                <table className="inner-table">
                                  <tbody>
                                    <tr>
                                      <td>
                                        <strong>Comapny Name:</strong>
                                      </td>
                                      <td>
                                        <span className="text-control">
                                          {el.company}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <strong>Comapny No:</strong>
                                      </td>
                                      <td>
                                        <span className="text-control">
                                          {el.company_no}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <strong>Certificate No:</strong>
                                      </td>
                                      <td>
                                        <span className="text-control">
                                          {el.certificate}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <strong>Certificate Type:</strong>
                                      </td>
                                      <td>
                                        <span className="text-control">
                                          {el.certificate_type}
                                        </span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td className="align-middle">
                                <table className="inner-table">
                                  <tbody>
                                    <tr>
                                      <td>
                                        <strong>Customer Id:</strong>
                                      </td>
                                      <td>
                                        {" "}
                                        <span className="text-control">
                                          {el.customer_id}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <strong>First Name:</strong>
                                      </td>
                                      <td>
                                        {" "}
                                        <span className="text-control">
                                          {el.fname}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <strong>Last Name:</strong>
                                      </td>

                                      <td>
                                        {" "}
                                        <span className="text-control">
                                          {el.lname}
                                        </span>
                                      </td>
                                    </tr>

                                    <tr>
                                      <td>
                                        <strong>Address1:</strong>
                                      </td>
                                      <td>
                                        <span className="text-control">
                                          {el.address1}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <strong>Address2:</strong>
                                      </td>
                                      <td>
                                        <span className="text-control">
                                          {el.address2}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <strong>Zip:</strong>
                                      </td>
                                      <td>
                                        {" "}
                                        <span className="text-control">
                                          {el.zip}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <strong>City:</strong>
                                      </td>
                                      <td>
                                        <span className="text-control">
                                          {el.city}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <strong>State:</strong>
                                      </td>
                                      <td>
                                        <span className="text-control">
                                          {el.state}
                                        </span>
                                      </td>
                                    </tr>

                                    <tr>
                                      <td>
                                        <strong>Phone:</strong>
                                      </td>
                                      <td>
                                        <span className="text-control">
                                          {el.phone}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <strong>Email:</strong>
                                      </td>
                                      <td>
                                        <span className="text-control">
                                          {el.email}
                                        </span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td className="align-middle">
                                <table className="inner-table">
                                  <tbody>
                                    <tr>
                                      <td>
                                        <strong>Begin Date:</strong>
                                      </td>
                                      <td>
                                        <span className="text-control date-span">
                                          {el.issuance !== "" &&
                                            el.issuance !== "0000-00-00" &&
                                            moment(el.issuance).format(
                                              "MM-DD-YYYY"
                                            )}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <strong>Expiration Date:</strong>
                                      </td>
                                      <td>
                                        <span className="text-control date-span">
                                          {el.expiration !== "" &&
                                            el.expiration !== "0000-00-00" &&
                                            moment(el.expiration).format(
                                              "MM-DD-YYYY"
                                            )}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <strong>Creation Date:</strong>
                                      </td>
                                      <td>
                                        <span className="text-control date-span">
                                          {el.created_at !== "" &&
                                            el.created_at !== "0000-00-00" &&
                                            moment(el.created_at).format(
                                              "MM-DD-YYYY"
                                            )}
                                        </span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>

                              <td className="align-middle">
                                <span className="text-control">
                                  <strong>Trip</strong> <br />
                                  {el.size.replace(/\s+/g, " ")}
                                </span>
                              </td>
                              <td className="align-middle">
                                <span
                                  className={`text-control font-17 text-capitalize ${
                                    el.status === "active" ||
                                    el.status === "Valid"
                                      ? "text-success"
                                      : el.status === "used"
                                      ? "text-primary"
                                      : el.status === "expired" ||
                                        el.status === "archive"
                                      ? "text-danger"
                                      : ""
                                  }`}
                                >
                                  {el.status !== "" ? el.status : "N/A"}
                                </span>
                              </td>

                              <td className="align-middle">
                                <span
                                  className={`text-control text-capitalize ${
                                    el.mail_status === "Certificate Sent"
                                      ? "text-success font-17"
                                      : el.mail_status === "Bounced Back"
                                      ? "text-danger font-17"
                                      : ""
                                  }`}
                                >
                                  {el.mail_status !== ""
                                    ? el.mail_status
                                    : "N/A"}
                                </span>
                              </td>

                              <td className="align-middle ">
                                <div className="">
                                  <Link
                                    to={`/admin/edit-voucher/${el.id}`}
                                    className="btn btn-success btn-sm btn-icon-text w-adjust"
                                  >
                                    Edit <i className="bi bi-pencil-square"></i>
                                  </Link>
                                  <br />
                                  <button
                                    type="button"
                                    className="btn btn-danger btn-sm btn-icon-text my-3 w-adjust"
                                    onClick={() => {
                                      delVoucher(el.id);
                                    }}
                                  >
                                    Delete <i className="bi bi-x-circle"></i>
                                  </button>
                                  <br />
                                  <button
                                    onClick={() => {
                                      archiveVoucher(el.id);
                                    }}
                                    className="btn btn-success btn-sm btn-icon-text w-adjust"
                                  >
                                    Archive <i className="bi bi bi-archive"></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan="9"
                              className="text-center text-capitalize"
                            >
                              No record found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    {totalResults > limit && totalPages > 1 ? (
                      <Pagination
                        activePage={currentPage}
                        itemsCountPerPage={parseInt(limit)}
                        totalItemsCount={totalResults}
                        onChange={(e) => {
                          vouchersLists(e, limit, values, currentYear);
                        }}
                        pageRangeDisplayed={8}
                        itemClass="page-item"
                        linkClass="page-link"
                        firstPageText="First Page"
                        lastPageText="Last Page"
                      />
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}
