import { useEffect, useRef, useState } from "react";

import "./style.css";
import Footer from "./Footer";
import SurvayPage from "./SurvayPage";

import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";

import UserListService from "../../services/user-list.service";

import secureLocalStorage from "react-secure-storage";
import Select from "react-select";
import { Icon } from "@iconify/react";
import Header from "./Header";

function UserForm() {
  const [fields, setFields] = useState({
    name: "",
    lname: "",
    address: "",

    city: "",
    state: "",
    zipcode: "",
    phonenumber: "",
    phonenumber1: "",
    phonenumber2: "",
    emailid: "",
  });

  const navigate = useNavigate();

  const [errors, setErrors] = useState({});
  const [states, setStates] = useState([]);

  const [value_three, setValue_three] = useState([]);

  const [i_agree_five, setI_agree_five] = useState(false);
  const [showPage, setShowPage] = useState(false);

  // const [signoutTime , setSignoutTime] = useState(1000 * 60 * 10);
  const signoutTime = 980000;

  const [selectGift, setSelectGift] = useState("");

  const ref = useRef();

  async function test() {
    try {
      const response = await UserListService.getStates();

      var states = response?.data?.response;
      const results = [];
      states.map((value) => {
        return results.push({
          value: value.code,
          label: value.name,
        });
      });
      await setStates([...results]);
    } catch (err) {
      if (err.status === 429 || err.status === "429") {
        alert("Max Limit Reached. Please wait.");
      }
    }

    try {
      const response_sheet = await UserListService.getTrips(
        localStorage.getItem("voucher")
      );

      const value_three = response_sheet.data.product;

      setValue_three(value_three);
    } catch (err) {
      if (err.status === 429 || err.status === "429") {
        alert("Max Limit Reached. Please wait.");
      }
    }
  }
  useEffect(() => {
    const Voucher = localStorage.getItem("voucher");

    if (
      Voucher === undefined ||
      Voucher === "undefined" ||
      Voucher === "null" ||
      Voucher === null
    ) {
      localStorage.setItem("returnpage", false);
      navigate("/");
    } else {
      setShowPage(true);
      timeout();
      test();
      localStorage.setItem("returnpage", true);
      const keysToKeep = [
        "voucher",
        "Survey",
        "isVoucher",
        "returnpage",
        "returnpage",
        "Logo",
      ]; // Specify the keys you want to keep
      function clearLocalStorageExcept(keysToKeep) {
        for (let key in localStorage) {
          if (!keysToKeep.includes(key)) {
            localStorage.removeItem(key);
          }
        }
      }

      clearLocalStorageExcept(keysToKeep);
    }
    // eslint-disable-next-line
  }, []);

  function timeout() {
    setTimeout(function () {
      logout();
    }, signoutTime);
  }

  const logout = () => {
    destroy();
  };

  const destroy = () => {
    localStorage.setItem("returnpage", false);
    localStorage.removeItem("voucher");
    window.location.replace("/");
  };

  const handleChange = (e) => {
    let { value, name } = e.target;

    setFields((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  const handleChangecheckboxfive = (e, value) => {
    let giftName = e.target.value;
    setSelectGift(giftName);
    setI_agree_five(!i_agree_five);
  };

  const submituserRegistrationForm = (e) => {
    e.preventDefault();
    if (validate()) {
      let fields = {};
      fields["voucher"] = "";
      fields["name"] = "";
      fields["lname"] = "";
      fields["address"] = "";

      fields["city"] = "";
      fields["state"] = "";
      fields["zipcode"] = "";
      fields["phonenumber"] = "";
      fields["phonenumber1"] = "";
      fields["phonenumber2"] = "";
      fields["emailid"] = "";
      fields["checkederror"] = "";
      fields["checkederrortwo"] = "";
      fields["checkederrorfive"] = "";
    }
  };

  const validate = async () => {
    let isformIsValid = false;

    let data = {
      fname: fields.name,
      lname: fields.lname,
      address: fields.address,
      city: fields.city,
      email: fields?.emailid,
      phone: fields.phonenumber + fields.phonenumber1 + fields.phonenumber2,
      state: fields.state,
      zip: fields.zipcode,
      product: selectGift,
      certificate: localStorage.getItem("voucher"),
    };

    try {
      const response = await UserListService.submitVoucher(data);

      isformIsValid = true;

      localStorage.setItem("isVoucher", true);
      localStorage.setItem("returnpage", false);
      secureLocalStorage.setItem(
        "UserForm",
        JSON.stringify({
          name: fields.name,
          lname: fields.lname,
          address: fields.address,

          city: fields.city,
          state: fields.state,
          zipcode: fields.zipcode,
          phonenumber: fields.phonenumber,
          phonenumber1: fields.phonenumber1,
          phonenumber2: fields.phonenumber2,
          emailid: fields.emailid,
          card: selectGift,
        })
      );
      navigate("/thank-you");
    } catch (err) {
      if (err.response.status === 429 || err.response.status === "429") {
        alert("Max Limit Reached. Please wait.");
      }
      isformIsValid = false;
      let errorData = {};
      errorData["name"] =
        err.response?.data &&
        err.response?.data?.fname &&
        err.response?.data?.fname[0];
      errorData["lname"] =
        err.response?.data &&
        err.response?.data?.lname &&
        err.response?.data?.lname[0];
      errorData["address"] =
        err.response?.data &&
        err.response?.data?.address &&
        err.response?.data?.address[0];

      errorData["city"] =
        err.response?.data &&
        err.response?.data?.city &&
        err.response?.data?.city[0];
      errorData["state"] =
        err.response?.data &&
        err.response?.data?.state &&
        err.response?.data?.state[0];
      errorData["zipcode"] =
        err.response?.data &&
        err.response?.data?.zip &&
        err.response?.data?.zip[0];
      errorData["phonenumber"] =
        err.response?.data &&
        err.response?.data?.phone &&
        err.response?.data?.phone[0];
      errorData["emailid"] =
        err.response?.data &&
        err.response?.data?.email &&
        err.response?.data?.email[0];

      errorData["checkederror"] =
        err.response?.data &&
        err.response?.data?.product &&
        err.response?.data?.product[0];
      errorData["checkederrortwo"] =
        err.response?.data &&
        err.response?.data?.product &&
        err.response?.data?.product[0];
      errorData["checkederrorfive"] =
        err.response?.data &&
        err.response?.data?.product &&
        err.response?.data?.product[0];

      errorData["certificate"] =
        err.response?.data &&
        err.response?.data?.certificate &&
        err.response?.data?.certificate[0];

      setErrors(errorData);
      setTimeout(() => {
        ref?.current?.focus();
      }, 200);
    }
    return isformIsValid;
  };

  const onlyNumbers = (evt, nextField) => {
    const { name, value } = evt.target;
    evt.target.value = evt.target.value.replace(/[^0-9]+/g, "");

    setFields((prevValue) => {
      return {
        ...prevValue,
        [name]: evt.target.value,
      };
    });
    if (evt.target.value !== "" && nextField && nextField !== "") {
      moveOnMax(evt, nextField);
    }
  };
  const moveOnMax = (field, nextFieldID) => {
    if (field.target.value.length >= field.target.maxLength) {
      document.getElementById(nextFieldID).focus();
    }
  };

  const pasted = (e) => {
    e.preventDefault();
    return false;
  };

  return (
    <>
      {!showPage && <div className="showPage"></div>}
      <Header />
      <div className="container-fluid">
        <div id="main-registration-container">
          <div className="white-box form">
            {/* <h1>Select Your Gift Site</h1> */}
            <h2>Contact Information</h2>
            {/* {GuestGreeting()} */}

            <form
              name="userRegistrationForm"
              className="form-outer"
              onSubmit={submituserRegistrationForm}
            >
              <div className="row">
                <div className="col-md-6">
                  <label className="formsub">
                    {" "}
                    First Name: <span className="red_point">*</span>{" "}
                  </label>
                  <input
                    type="text"
                    // placeholder="First Name"
                    style={errors.name ? { border: "1px solid red" } : null}
                    name="name"
                    tabIndex="1"
                    id="fname"
                    className="searchBox_deals vc-validate"
                    ref={errors.name ? ref : null}
                    value={fields.name}
                    autoFocus=""
                    onChange={handleChange}
                  />
                  <div className="errorMsg">{errors.name}</div>
                </div>
                <div className="col-md-6">
                  <label className="formsub">
                    {" "}
                    Last Name <span className="red_point">*</span>{" "}
                  </label>
                  <input
                    type="text"
                    name="lname"
                    style={
                      errors.lname && !errors.name
                        ? { border: "1px solid red" }
                        : null
                    }
                    // placeholder="Last Name"
                    tabIndex="1"
                    id="lname"
                    className="searchBox_deals vc-validate"
                    value={fields.lname}
                    ref={errors.lname && !errors.name ? ref : null}
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.lname && !errors.name ? errors.lname : null}
                  </div>
                </div>

                <div className="clearfix"></div>

                <div className="col-md-6">
                  <label required className="formsub">
                    Address <span className="red_point">*</span>
                  </label>

                  <input
                    type="text"
                    style={
                      errors.address && !errors.lname && !errors.name
                        ? { border: "1px solid red" }
                        : null
                    }
                    name="address"
                    // placeholder="Address"
                    tabIndex="1"
                    id="address"
                    ref={
                      errors.address && !errors.lname && !errors.name
                        ? ref
                        : null
                    }
                    className="searchBox_deals vc-validate"
                    autoFocus=""
                    value={fields.address}
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.address && !errors.lname && !errors.name
                      ? errors.address
                      : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label className="formsub">
                    {" "}
                    City <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    id="city"
                    name="city"
                    style={
                      errors.city &&
                      !errors.address &&
                      !errors.lname &&
                      !errors.name
                        ? { border: "1px solid red" }
                        : null
                    }
                    tabIndex="4"
                    // placeholder="City"
                    ref={
                      errors.city &&
                      !errors.address &&
                      !errors.lname &&
                      !errors.name
                        ? ref
                        : null
                    }
                    value={fields.city}
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.city &&
                    !errors.address &&
                    !errors.lname &&
                    !errors.name
                      ? errors.city
                      : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label className="formsub">
                    State <span className="red_point">*</span>
                  </label>

                  <Select
                    classNamePrefix="react-select"
                    className={`select_1 dropdown ${
                      errors.state &&
                      !errors.city &&
                      !errors.address &&
                      !errors.lname &&
                      !errors.name
                        ? "error-select-search"
                        : ""
                    }`}
                    placeholder="Select State"
                    options={states}
                    isSearchable={true}
                    isClearable={true}
                    tabIndex="5"
                    name="state"
                    ref={
                      errors.state &&
                      !errors.city &&
                      !errors.address &&
                      !errors.lname &&
                      !errors.name
                        ? ref
                        : null
                    }
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        setFields((prevValue) => {
                          return {
                            ...prevValue,
                            ["state"]: selectedOption.value,
                          };
                        });
                      } else {
                        setFields((prevValue) => {
                          return {
                            ...prevValue,
                            ["state"]: "",
                          };
                        });
                      }
                    }}
                  />

                  <div className="errorMsg">
                    {errors.state &&
                    !errors.city &&
                    !errors.address &&
                    !errors.lname &&
                    !errors.name
                      ? errors.state
                      : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label className="formsub">
                    Zip Code <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    id="zipcode"
                    style={
                      errors.zipcode &&
                      !errors.state &&
                      !errors.city &&
                      !errors.address &&
                      !errors.lname &&
                      !errors.name
                        ? { border: "1px solid red" }
                        : null
                    }
                    name="zipcode"
                    maxLength="10"
                    tabIndex="6"
                    value={fields.zipcode}
                    ref={
                      errors.zipcode &&
                      !errors.state &&
                      !errors.city &&
                      !errors.address &&
                      !errors.lname &&
                      !errors.name
                        ? ref
                        : null
                    }
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.zipcode &&
                    !errors.state &&
                    !errors.city &&
                    !errors.address &&
                    !errors.lname &&
                    !errors.name
                      ? errors.zipcode
                      : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="phone-outer">
                    <label className="formsub">
                      Phone No <span className="red_point">*</span>
                    </label>
                    <input
                      type="text"
                      className="phone"
                      style={
                        errors.phonenumber &&
                        fields?.phonenumber?.length < 3 &&
                        !errors.state &&
                        !errors.zipcode &&
                        !errors.city &&
                        !errors.address &&
                        !errors.lname &&
                        !errors.name
                          ? { border: "1px solid red" }
                          : null
                      }
                      ref={
                        errors.phonenumber &&
                        fields?.phonenumber?.length < 3 &&
                        !errors.state &&
                        !errors.zipcode &&
                        !errors.city &&
                        !errors.address &&
                        !errors.lname &&
                        !errors.name
                          ? ref
                          : null
                      }
                      maxLength="3"
                      id="phonenumber"
                      name="phonenumber"
                      onKeyUp={(e) => {
                        onlyNumbers(e, "phonenumber1");
                      }}
                      onPaste={pasted}
                      tabIndex="7"
                      // onKeyUp="moveOnMax(this,'phonenumber1')"
                      value={fields.phonenumber}
                      onChange={handleChange}
                    />{" "}
                    -
                    <input
                      type="text"
                      className="phone"
                      style={
                        errors.phonenumber &&
                        fields.phonenumber !== "" &&
                        fields?.phonenumber1?.length < 3 &&
                        fields?.phonenumber?.length === 3 &&
                        !errors.state &&
                        !errors.zipcode &&
                        !errors.city &&
                        !errors.address &&
                        !errors.lname &&
                        !errors.name
                          ? { border: "1px solid red" }
                          : null
                      }
                      ref={
                        errors.phonenumber &&
                        fields.phonenumber !== "" &&
                        fields?.phonenumber1?.length < 3 &&
                        fields?.phonenumber?.length === 3 &&
                        !errors.state &&
                        !errors.zipcode &&
                        !errors.city &&
                        !errors.address &&
                        !errors.lname &&
                        !errors.name
                          ? ref
                          : null
                      }
                      maxLength="3"
                      id="phonenumber1"
                      name="phonenumber1"
                      tabIndex="8"
                      // onKeyUp="moveOnMax(this,'phonenumber2')"
                      value={fields.phonenumber1}
                      onChange={handleChange}
                      onKeyUp={(e) => {
                        onlyNumbers(e, "phonenumber2");
                      }}
                      onPaste={pasted}
                    />{" "}
                    -
                    <input
                      type="text"
                      className="phone"
                      style={
                        errors.phonenumber &&
                        fields?.phonenumber2?.length < 4 &&
                        fields.phonenumber1 !== "" &&
                        fields.phonenumber !== "" &&
                        fields?.phonenumber?.length === 3 &&
                        !errors.state &&
                        !errors.zipcode &&
                        !errors.city &&
                        !errors.address &&
                        !errors.lname &&
                        !errors.name
                          ? { border: "1px solid red" }
                          : null
                      }
                      ref={
                        errors.phonenumber &&
                        fields?.phonenumber2?.length < 4 &&
                        fields.phonenumber1 !== "" &&
                        fields.phonenumber !== "" &&
                        fields?.phonenumber?.length === 3 &&
                        fields?.phonenumber1?.length === 3 &&
                        !errors.state &&
                        !errors.zipcode &&
                        !errors.city &&
                        !errors.address &&
                        !errors.lname &&
                        !errors.name
                          ? ref
                          : null
                      }
                      maxLength="4"
                      id="phonenumber2"
                      name="phonenumber2"
                      tabIndex="9"
                      // onKeyUp="moveOnMax(this,'email')"
                      value={fields.phonenumber2}
                      onChange={handleChange}
                      onKeyUp={onlyNumbers}
                      onPaste={pasted}
                    />
                    <div className="errorMsg">
                      {errors.phonenumber &&
                      !errors.zipcode &&
                      !errors.state &&
                      !errors.city &&
                      !errors.address &&
                      !errors.lname &&
                      !errors.name
                        ? errors.phonenumber
                        : null}
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <label className="formsub">
                    Email <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    name="emailid"
                    tabIndex="10"
                    ref={
                      errors.emailid &&
                      !errors.state &&
                      !errors.phonenumber &&
                      !errors.zipcode &&
                      !errors.city &&
                      !errors.address &&
                      !errors.lname &&
                      !errors.name
                        ? ref
                        : null
                    }
                    style={
                      errors.emailid &&
                      !errors.state &&
                      !errors.phonenumber &&
                      !errors.zipcode &&
                      !errors.city &&
                      !errors.address &&
                      !errors.lname &&
                      !errors.name
                        ? { border: "1px solid red" }
                        : null
                    }
                    id="emailid"
                    // placeholder="E-mail"
                    value={fields.emailid}
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.emailid &&
                    !errors.phonenumber &&
                    !errors.state &&
                    !errors.zipcode &&
                    !errors.city &&
                    !errors.address &&
                    !errors.lname &&
                    !errors.name
                      ? errors.emailid
                      : null}
                  </div>
                </div>

                <h2>Please select the Gift you wish to receive:</h2>

                <br />

                <div className="clearfix"></div>
                <div className="col-md-12">
                  <span style={{ float: "left" }} id="color_msg22"></span>

                  <div className="errorMsg">{errors.checkederrortwo}</div>

                  <span
                    className="error_message_forimage"
                    id="color_msg22"
                  ></span>
                  {value_three.map((insdex) => {
                    return (
                      <div key={insdex.name} className="check-boexs cen">
                        <label>
                          <input
                            type="radio"
                            name="name"
                            id={insdex.name}
                            key={insdex.name}
                            value={insdex.name}
                            onChange={handleChangecheckboxfive}
                          />
                          <span className="insdex">{insdex.name}</span>
                        </label>
                      </div>
                    );
                  })}
                </div>

                <div></div>
              </div>
              <hr />

              <button type="submit" name="submit" className="submit-btn">
                SUBMIT
              </button>
            </form>
            <div className="footer-info">
              <ul className="info-list clearfix">
                <li>
                  <a href="tel:800-585-9806">
                    <span className="phone-icon">
                      <Icon icon="fluent:call-48-filled" />
                    </span>
                    800-585-9806
                  </a>
                </li>
                <li>
                  <a href="mailto:customercare@eliterewards.biz">
                    <Icon icon="clarity:email-solid" />
                    customercare@eliterewards.biz
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default UserForm;
